<template>
  <div class="card mt-4 ml-5 mr-5" style="min-height: 70vh;">  
    <b-tabs :animated="false">
      <b-tab-item :label="t('componentNames.mentorExams')" icon="file-multiple">
        <ExamsList :refreshTrigger="refreshTrigger"></ExamsList>
      </b-tab-item>
      <b-tab-item :label="t('componentNames.newExamSchema')" icon="file-plus">
        <ExamCreator @examAdded="examAdded"></ExamCreator>
      </b-tab-item>      
    </b-tabs>    
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import ExamsList from "./ExamsList";
import ExamCreator from "./examCreate/ExamCreator"
///import { filter } from 'vue/types/umd';
//import UserFeedback from '../feedbacks/UserFeedback'

export default {
  name: "ExamsMentor",
  mixins: [CommonHelpers],
  components: {
    ExamsList,
    ExamCreator,
    //UserFeedback
  },

  props: {},

  data: function () {
    return {
      refreshTrigger: 0,
      selectTab: 0
    };
  },

  mounted() {
  },

  watch: {},

  computed: {
  },

  methods: {

    examAdded(){
      this.refreshTrigger++;
      this.selectTab =0;
    }
    
  },
};
</script>

<style scoped>
</style>