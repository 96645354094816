<template>
  <div class="home">    
    <ExamsMentor />
  </div>
</template>

<script>
// @ is an alias to /src
import ExamsMentor from '@/components/exam/ExamsMentor'

export default {
  name: 'ExamsMentorView',
  components: {
    ExamsMentor
  }
}
</script>
