<template>
  <div>
    <UserFeedback :target="t('componentNames.mentorExams')" class="end mr-4 mt-1" :position="'is-left'"></UserFeedback>
    <b-field grouped group-multiline class="pb-1">
      <b-field class="pr-5">
        <b-button icon-left="refresh" :loading="isLoading" @click="getExams" size="is-small">{{t('common.refresh')}}</b-button>
      </b-field>
      <b-field>
        <b-field>
          <b-checkbox v-model="showTests" class="mr-5">
            <span>{{t('classroom.types.examTest')}}</span>
          </b-checkbox>
          <b-checkbox v-model="showImages">
            <span>{{t('classroom.types.examMultimedia')}}</span>
          </b-checkbox>
          <b-checkbox v-model="showCombo">
            <span>{{t('classroom.types.examCombo')}}</span>
          </b-checkbox>
        </b-field>
      </b-field>
    </b-field>
    <b-table narrowed :data="filteredExams" striped hoverable>
      <b-table-column :custom-sort="sortByLp" :label="t('common.lp')" sortable centered v-slot="props">{{ filteredExams != null ? filteredExams.indexOf(props.row) + 1 : props.index + 1}}</b-table-column>
      <b-table-column field="type" sortable :label="t('common.type')" centered v-slot="props">
        <b-tooltip :label="mExamTypeName(props.row.type)" position="is-right">
          <b-icon :icon="mExamTypeIcon(props.row.type)" size="is-medium" type="is-primary"></b-icon>
        </b-tooltip>
      </b-table-column>
      <b-table-column
        field="title"
        searchable
        sortable
        :label="t('forms.title')"
        v-slot="props"
      >{{ props.row.title }}</b-table-column>
      <b-table-column
        field="description"
        searchable
        sortable
        :label="t('forms.description')"
        v-slot="props"
      >{{ props.row.description }}</b-table-column>
      <b-table-column
        field="length"
        searchable
        sortable
        centered
        :label="t('forms.numberQuestions')"
        v-slot="props"
      >{{ props.row.questions.length }}</b-table-column>
      <b-table-column
        field="time"
        searchable
        sortable
        centered
        :label="t('forms.timeMinutes')"
        v-slot="props"
      >{{ props.row.maxTime }}</b-table-column>
      <b-table-column field="tags" searchable sortable :label="t('forms.tags')" v-slot="props">
        <b-taglist>
          <b-tag v-for="tag in props.row.tags" :key="tag" size="is-small">
            {{
            tag
            }}
          </b-tag>
        </b-taglist>
      </b-table-column>
      <b-table-column field="option" :label="t('common.actions')" v-slot="props">
        <div class="buttons">
          <b-tooltip type="is-warning" :label="t('classroom.previewExam')">
          <b-button
            icon-left="eye"
            @click="showExam(props.row.id, props.row.type)"
            size="is-small"
            type="is-warning"
          ></b-button>
          </b-tooltip>
          <b-tooltip type="is-success" :label="t('common.edit')">
          <b-button
            icon-left="file-edit"
            @click="editExam(props.row.id)"
            size="is-small"
            type="is-success"
          ></b-button>
          </b-tooltip>
        </div>
      </b-table-column>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="package-variant" size="is-large"></b-icon>
            </p>
            <p>{{t('common.noData')}}</p>
          </div>
        </section>
      </template>
    </b-table>
    <b-modal v-model="isModalActive">
      <div class="modal-content exam-modal">
        <ExamPreview :idExam="idExam" />
      </div>
    </b-modal>
    <b-modal full-screen v-model="isModalEditActive" :can-cancel="false">
      <template #default="props">
        <div class="section">
          <ExamCreator
            :idExam="idExam"
            @close="props.close"
            @changeSave="getExams"
            @deleteExam="getExams"
          />
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { Action } from "../../store/config_actions";
import CommonHelpers from "@/mixins/commons";
import TypesHelpers from "@/mixins/types";
import ExamPreview from "./examMentor/ExamPreview";
import ExamCreator from "./examCreate/ExamCreator";
import UserFeedback from "@/components/feedbacks/UserFeedback";
///import { filter } from 'vue/types/umd';

export default {
  name: "ExamsList",
  mixins: [CommonHelpers, TypesHelpers],
  components: {
    ExamPreview,
    ExamCreator,
    UserFeedback,
  },

  props: {
    refreshTrigger: { type: Number, required: true },
  },

  data: function () {
    return {
      examsData: [],
      isLoading: false,
      activedExam: null,
      showTests: true,
      showImages: true,
      showCombo: true,
      examWordSearch: "",
      isModalActive: false,
      isModalEditActive: false,
      idExam: null,
    };
  },

  mounted() {
    this.getExams();
  },

  watch: {
    refreshTrigger() {
      this.getExams();
    },
  },

  computed: {
    filteredExams() {
      if (this.examsData) {
        let filtered = this.examsData;

        if (!this.showImages) {
          filtered = filtered.filter((ex) => ex.type === 0);
        }
        if (!this.showTests) {
          filtered = filtered.filter((ex) => ex.type === 1);
        }
        
        if (!this.showCombo) {
          filtered = filtered.filter((ex) => ex.type === 2);
        }
        return filtered;
      } else return [];
    },

    session() {
      return this.$store.state.auth.session;
    },

    authorName() {
      return this.session.firstName + " " + this.session.lastName;
    },
  },

  methods: {
    showExam(id, type) {
      if (type === 0) {
        this.idExam = id;
        this.isModalActive = !this.isModalActive;
      } else if (type === 1) {
        this.$router.push({
          name: "examimagepreview",
          params: { idExam: id, isCombo: false },
        });
      } else if (type === 2) {
        this.$router.push({
          name: "examimagepreview",
          params: { idExam: id, isCombo: true },
        });
      }
    },

    editExam(id) {
      this.idExam = id;
      this.isModalEditActive = !this.isModalEditActive;
    },

    getExams() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.EXAM_GET_ALL)
        .then((payload) => {
          this.examsData = payload;
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    sortByLp(first, second, isAsc){
        if(this.filteredExams != null){
          let indexFirst = this.filteredExams.indexOf(first);
          let indexSecond = this.filteredExams.indexOf(second);
          if(isAsc){
            return indexSecond - indexFirst
          }else{
            return indexFirst- indexSecond
          }
        }else{
          return 0
        }
    }
  },
};
</script>

<style scoped>
.exam-modal {
  background-color: white;
}
div.end {
  float: right;
}
</style>